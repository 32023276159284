.startup-card-div {
  color: rgb(88, 96, 105);
  background-color: rgb(255, 255, 255);
  padding: 2rem;
  cursor: pointer;
  display: table-cell;
  height: 150px;
  vertical-align: middle;
}
.startup-card-div img {
  width:100%; 
  height:100%; 
}